import Lightbox from '@/Components/Common/Lightbox.jsx';
import RadioGroupField from '@/Components/Fields/RadioGroupField.jsx';
import RadioChoices from '@/Pages/Configurator/Components/Fields/RadioChoices.jsx';
import {Fieldset, FieldsetImage} from '@/Pages/Configurator/Styles/Fieldset.jsx';
import ImagesStyle from '@/Styles/images.js';
import {Box, Link as MuiLink, Typography} from '@mui/material';
import React, {memo} from 'react';

let styles = {
    picture_link: {
        display: 'block',
    },
};

const GarageFieldset = ({url_brand, data, errors, garage_select_options, handleNumericOptionChange}) => {
    let decor = {image: '/images/pages/configurator/decors/decor02.jfif', width: 1920, height: 1080};

    switch (url_brand) {
        case 'ya-k':
            styles.picture_link = (theme) => ({
                ...styles.picture_link,
                position: 'relative',
                '&::after': {
                    ...ImagesStyle.background_contain('/theme/ya-k/images/icons/arbre.svg'),
                    content: '""',
                    position: 'absolute',
                    bottom: 0,
                    right: '26%',
                    transform: 'translateY(35%)',
                    width: '65px',
                    height: '65px',
                    zIndex: 999,
                    [theme.breakpoints.up('md')]: {
                        right: '22%',
                        width: '98px',
                        height: '98px',
                    },
                },
            });
            break;
        case 'couleur-villas':
            break;
    }

    return (
        <Fieldset>
            <Box>
                <Typography variant="fancy_h5" component="h2" sx={{textAlign: {xs: 'center', md: 'start'}}}>
                    Extérieur
                </Typography>
            </Box>

            <Lightbox id={'gallery-exterior'}>
                <MuiLink
                    href={decor.image}
                    data-pswp-width={decor.width}
                    data-pswp-height={decor.height}
                    target="_blank"
                    rel="noreferrer"
                    sx={styles.picture_link}
                >
                    <FieldsetImage src={decor.image} alt="" />
                </MuiLink>
            </Lightbox>

            <Box>
                <Typography variant="body1" component="p" sx={{mb: {xs: 2, md: 4}}}>
                    Présence garage
                </Typography>
                <RadioGroupField
                    fullWidth
                    error={!!errors.garage}
                    helperText={errors.garage}
                    id="garage"
                    name="garage"
                    sx={{
                        '& .MuiFormControlLabel-asterisk': {
                            display: 'none',
                        },
                    }}
                    RadioGroupProps={{
                        value: data.garage,
                        sx: {gap: 2},
                        onChange: handleNumericOptionChange,
                    }}
                >
                    <RadioChoices items={garage_select_options} current_value={data.garage} />
                </RadioGroupField>
            </Box>
        </Fieldset>
    );
};

export default memo(GarageFieldset);
